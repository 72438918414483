<template lang="pug">
.clients(v-if="block")
  .clients__header(v-html="block.title")
  .clients__rows
    .clients__row.logos(ref="logosRow1")
      .logos-slide(ref="logosContent1")
        ImageTmp(
          v-for="(logo, index) in [...block.logosRow1, ...block.logosRow1]"
          :key="index"
          :image="logo"
          height="30"
        )
    .clients__row.logos(ref="logosRow2")
      .logos-slide(ref="logosContent2")
        ImageTmp(
          v-for="(logo, index) in [...block.logosRow2, ...block.logosRow2]"
          :key="index"
          :image="logo"
          height="30"
        )
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick } from "vue";
import gsap from "gsap";

interface IProps {
  block?: {
    title?: string;
    logosRow1?: { image: string }[];
    logosRow2?: { image: string }[];
  };
}

const props = defineProps<IProps>();

const logosRow1 = ref<HTMLElement | null>(null);
const logosRow2 = ref<HTMLElement | null>(null);
const logosContent1 = ref<HTMLElement | null>(null);
const logosContent2 = ref<HTMLElement | null>(null);

const startInfiniteScroll = (target: HTMLElement, direction: number, duration: number) => {
  gsap.to(target, {
    xPercent: -100 * direction,
    duration: duration,
    ease: "none",
    repeat: -1,
    modifiers: {
      xPercent: gsap.utils.wrap(-100, 0),
    },
  });
};

onMounted(() => {
  nextTick(() => {
    if (logosContent1.value) startInfiniteScroll(logosContent1.value, 1, 15); // left
    if (logosContent2.value) startInfiniteScroll(logosContent2.value, -1, 15); // right
  });
});
</script>

<style lang="sass" scoped>
.clients
  background: #034033
  padding-top: 117px
  padding-bottom: 122px

  &__header
    margin-bottom: 65px
    color: #FFF
    text-align: center
    font-size: 20px
    font-weight: 700
    line-height: 24px

  &__rows
    display: flex
    flex-direction: column
    gap: 56px

  &__row.logos
    overflow: hidden
    white-space: nowrap
    position: relative

    &:before, &:after
      content: ""
      position: absolute
      top: 0
      width: 142px
      height: 100%
      z-index: 2
    
    &:before
      left: 0
      background: linear-gradient(90deg, #034033 51.55%, rgba(3, 64, 51, 0.00) 85.21%)
    
    &:after
      right: 0
      background: linear-gradient(270deg, #034033 51.55%, rgba(3, 64, 51, 0.00) 85.21%)

.logos-slide
  display: flex
  align-items: center
  will-change: transform

.logos-slide :deep(img)
  margin: 0 30px
  height: 30px
  width: auto
  max-width: max-content

@media (max-width: 768px)
  .clients
    padding: 71px 0

    &__header
      font-size: 14px
      line-height: 20px
      margin-bottom: 40px

    &__rows
      gap: 24px

    &__row.logos
      &:before, &:after
        width: 60px

    .logos-slide :deep(img)
      margin: 0 12px
</style>